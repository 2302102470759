.testimonial-section {
  padding: 20px;
  color: black;
  margin-top: 50px;
  margin-left: auto;
  border-radius: 20px;
  height: 90vh;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .testimonial-section {
  box-shadow: none;
  color: white;
  background: linear-gradient(to bottom, #000099 0%, #0033cc 100%);
  border-radius: 20px;
  box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -webkit-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -moz-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
}

.content-slider-main {
  margin-top: 50px;
}

.content-slider {
  padding: 10px;
  color: white;
  background: linear-gradient(to bottom, #00ccff 0%, #0000ff 100%);
  margin-top: 50px;
  text-align: center;
  margin-bottom: 60px;
  cursor: pointer;
  height: 340px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
  -webkit-box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
  -moz-box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
}

.content-slider:hover {
  background: linear-gradient(to bottom, #66ccff 0%, #0066ff 100%);
  color: #00ffff;
  cursor: pointer;
  transition-duration: 1s;

  -webkit-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  -moz-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
}

.content-slider img.center-image {
  height: 80px;
  width: 80px;
  border-radius: 90px;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  border: 2px solid #00ccff;
}
.slick-prev:before {
  color: #0000ff !important;
  font-size: 30px;
}
.slick-next:before {
  color: #0000ff !important;
  font-size: 30px;
}
.slick-disable {
  color: #00ccff;
}
