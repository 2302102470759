.project-list {
  margin: auto;
  margin-top: 40px;
  padding: 10px;
  border-radius: 10px;

  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}

.project-list-open {
  border-left: 20px solid blue;
}

.project-list:hover {
  border-left: 20px solid blue;
  cursor: pointer;
  transition-duration: 1s;

  -webkit-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  -moz-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
}

.title-and-collapse-option {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.tech-used-in-project {
  margin-top: 20px;
  margin: 5px;
}

.tech-used-in-project p:hover {
  background: linear-gradient(to bottom, #33cccc 0%, #0066ff 100%);
  width: 135px;
  color: black;
  transition-duration: 1s;
}

.tech-used-in-project p {
  background: linear-gradient(to bottom, #00ccff 0%, #000099 100%);
  color: white;
  border-radius: 90px;
  padding: 8px;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.live-demo-button {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  width: 150px;
  padding: 5px;
  color: white;
  text-align: center;
  justify-content: center;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 90px;
  cursor: pointer;
  margin-top: 35px;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.live-demo-button:hover {
  background: linear-gradient(to bottom, #00ffff 0%, #0033cc 100%);
  width: 250px;
  color: black;
  transition-duration: 1s;
}

.live-demo-button a {
  color: white;
  text-decoration: none; /* Remove the underline from Link */
  padding: 10px;
}
.description {
  margin: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
