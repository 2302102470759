::-webkit-scrollbar {
  width: 10px;
  height: auto;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(13, 28, 116);
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  border-radius: 5px;
}
#dark {
  background: linear-gradient(to bottom, #0000ff 0%, #000099 100%);
  color: white;
}
