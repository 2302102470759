.about-section {
  margin-top: 30px;

  height: 500px;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .about-section {
  box-shadow: none;
  background: linear-gradient(to bottom, #0033cc 0%, #000099 100%);
  border-radius: 20px;
  box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -webkit-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -moz-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
}

.about-image img {
  height: 400px;
  width: 300px;
  border-radius: 190px;
  margin-top: 50px;
  margin-left: 25px;

  object-fit: cover;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}

.about-details {
  margin-top: 50px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.about-title h5 {
  text-align: center;
}

.line {
  display: block;
  width: 70px;
  height: 3px;
  margin: 10px auto;
  margin-bottom: 30px;
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
}
@media (max-width: 1000px) {
  .about-section {
    height: auto;
  }
}
