.techstack-section {
  margin-top: 90px;
  height: auto;
  margin-bottom: 40px;
  margin-left: auto;
}
#dark .techstack-section {
  box-shadow: none;
  border-radius: 20px;
}

.section-title h5 {
  text-align: center;
}

.tech-content {
  padding: 10px;
  color: black;
  margin-top: 50px;
  border-radius: 20px;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .tech-content {
  color: white;
}

.tech-content p {
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.tech-content:hover {
  background: linear-gradient(to bottom, #66ccff 0%, #0066ff 100%);
  color: #00ffff;
  cursor: pointer;
  transition-duration: 1s;

  -webkit-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  -moz-box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
  box-shadow: 8px 2px 19px 20px rgba(113, 170, 237, 1);
}

.tech-number {
  border-radius: 90px;
  height: 50px;
  width: 50px;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  line-height: 50px;
}

.load-more-teck-stack {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  width: 150px;
  padding: 10px;
  color: white;
  text-align: center;
  justify-content: center;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 90px;
  cursor: pointer;
  margin-top: 35px;
  border: 1px solid black;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.load-more-teck-stack:hover {
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  width: 250px;
  color: black;
  transition-duration: 1s;
}

.tech-content-marked {
  background: linear-gradient(to bottom, #00ccff 0%, #0066ff 100%);
  color: #00ffff;
}
