.home {
  height: 400px;
  color: white;
}

.home-content {
  margin: auto;
  padding: 10px;
  margin-top: 100px;
}

.home-content h2 {
  margin-top: 20px;
  color: #00ffff;
}

.button-for-action {
  display: flex;
}

.hire-me-button {
  background: linear-gradient(to bottom, #00ccff 0%, #0000ff 100%);
  text-align: center;
  width: 180px;
  padding: 10px;
  border-radius: 90px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid black;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.hire-me-button:hover {
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  width: 250px;
  color: black;
  transition-duration: 1s;
}

.get-resume-button {
  background: linear-gradient(to bottom, #00ccff 0%, #0000ff 100%);
  text-align: center;
  width: 180px;
  padding: 10px;
  border-radius: 90px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid black;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.get-resume-button:hover {
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  color: black;
  width: 250px;
  transition-duration: 1s;
}

.get-resume-button a {
  text-decoration: none;
  color: black;
}
.theme-change {
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: #0000ff;
  right: 0;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  position: fixed;
  top: 150;
  margin-top: 250px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
}
.sun-theme-icon {
  animation: rotation infinite 5s linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1000px) {
  .theme-change {
    display: none;
  }
}
