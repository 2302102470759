.sidebar-section {
  display: flex;
  background: linear-gradient(to bottom, #000000 0%, #00008f 100%);
}

.sidebar {
  height: 100vh;
  /* background-color: #282022; */
  background: linear-gradient(to bottom, #000000 0%, #00008f 100%);
  /* background: linear-gradient(to bottom, #303030 0%, #000000 100%); */
  width: 95px;
  position: fixed;
}

.sidebar-expand {
  width: 250px;
}

.icon-for-sidebar-expand-and-collapse {
  display: flex;
  align-items: right;
  justify-content: right;
  cursor: pointer;
}

.icon-for-sidebar-expand-and-collapse p svg {
  background-color: #19092c;
  padding: 5px;
  color: white;
  border-radius: 5px;
  margin-top: 50px;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
}
