.contact-section {
  height: 1200px;
  padding: 20px;
  color: black;
  margin-top: 50px;

  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .contact-section {
  box-shadow: none;
  color: white;
  background: linear-gradient(to bottom, #000099 0%, #0033cc 100%);
  border-radius: 20px;
  box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -webkit-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -moz-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
}
.contact-form-image img {
  margin-top: 50px;
  height: 490px;
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
  box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
  -webkit-box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
  -moz-box-shadow: 10px -5px 28px -7px rgba(73, 102, 201, 1);
}
.contact-form-design {
  margin-top: 50px;
}
.contact-form input {
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}
#dark .contact-form input {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  border: 2px solid rgba(44, 226, 226, 0.64);
}

.contact-form textarea {
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}
#dark .contact-form textarea {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  border: 2px solid rgba(44, 226, 226, 0.64);
}

.custom-select-tag {
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}
#dark .custom-select-tag {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  border: 2px solid rgba(44, 226, 226, 0.64);
}

.button-submit {
  background: linear-gradient(to bottom, #0066ff 0%, #0000ff 100%);
  width: 200px;
  padding: 10px;
  color: white;
  text-align: center;
  justify-content: center;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 90px;
  cursor: pointer;
  margin-top: 35px;

  border: 1px solid white;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.button-submit p {
  margin: 0 auto;
}

.button-submit:hover {
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  width: 250px;
  color: black;
  transition-duration: 1s;
}
