.date-class {
  color: darkblue;
}
.education-section {
  padding: 20px;
  color: black;
  margin-top: 50px;
  margin-left: auto;
  margin-bottom: 50px;
  border-radius: 20px;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .education-section {
  box-shadow: none;
}

#dark .education-section {
  box-shadow: none;
  color: white;
  background: linear-gradient(to bottom, #000099 0%, #0033cc 100%);
  border-radius: 20px;
  box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -webkit-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -moz-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
}
.education-title {
  margin-top: 30px;
}
