.tech-skills {
  margin-top: 20px;
  margin: 5px;
}

.tech-skills p:hover {
  background: linear-gradient(to bottom, #33cccc 0%, #0066ff 100%);
  width: 135px;
  color: black;
  transition-duration: 1s;
}

.tech-skills p {
  background: linear-gradient(to bottom, #00ccff 0%, #000099 100%);
  color: white;
  border-radius: 90px;
  padding: 8px;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.date-class {
  color: darkblue;
}

#dark .date-class {
  color: white;
}

.workexperience-section {
  padding: 20px;
  color: black;
  margin-top: 50px;
  margin-left: auto;
  border-radius: 20px;
  box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -webkit-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
  -moz-box-shadow: 17px 22px 25px 6px rgba(10, 60, 184, 0.64);
}
#dark .workexperience-section {
  box-shadow: none;
  color: white;
  background: linear-gradient(to bottom, #000099 0%, #0033cc 100%);
  border-radius: 20px;
  box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -webkit-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
  -moz-box-shadow: 10px 10px 12px -1px rgba(242, 235, 235, 0.29);
}
.workexperience-title {
  margin-top: 20px;
}
