.mobile-view-navbar {
  position: sticky;
  top: 0;
  z-index: 1;
}
.navbar-header {
  width: 100%;
  background: linear-gradient(to bottom, #66ccff 0%, #0066ff 100%);
  color: white;
  padding: 5px;
}

.navbar-header p svg {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.mobile-nav {
  height: auto;
  width: 210px;
  background: linear-gradient(to bottom, #66ccff 0%, #0066ff 100%);
  margin-top: 1px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mobile-nav ul li {
  color: white;
  list-style-type: none;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
}
.mobile-nav ul li a {
  text-decoration: none;
  color: white;
}
.nav-item-mobile-view .active {
  color: black;
  background: linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  border-radius: 90px;
  padding: 5px;
  width: 100%;
}

@media (min-width: 1000px) {
  .mobile-view-navbar {
    display: none;
  }
}
